:root .ProgressBar {
  --border: #dadce0;
}

:root:global(.dark) .ProgressBar {
  --border: #2c2c2c;
}

.ProgressBar {
  border-color: var(--border);
}
.segment {
  transition: all 1s;
  transition-timing-function: cubic-bezier(0, 1, 0, 1);
}

.hewa {
  background: #ff5c4ec2;
}
.pololei {
  background: #57d728bf;
}
.koe {
  background: #60bcdc40;
}
