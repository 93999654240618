:root:global(.dark) .Table {
  --background: #000;
  --color: #fff;
  --table-border-color: #444;
  --table-header-background: #262626;
  --table-header-color: #fff;
  --background-color-row-odd: #0d0d0d;
  --background-color-row-even: #0d0d0d;
  --background-color-row-first: #0d0d0d;
  --cell-color: #0d0d0d;
  --cell-selected-color: #222;
  --ctx-background: #111;
  --ctx-background-hover: #222;
  --scroll-track: #111;
  --scroll-thumb: #575757;
  --scroll-thumb-hover: #575757;

  //   body {
  //     background-color: var(--background);
  //     color: var(--color);
  //   }

  //   :global(#data-sheet) {
  //     border-top: thin solid var(--table-border-color);
  //     border-bottom: thin solid var(--table-border-color);
  //   }

  :global(.handsontable) {
    color: var(--color);
  }

  //   :global(.wtHolder) {
  //     background-color: var(--table-header-background);
  //   }

  //   :global(.htCore) tbody tr:nth-of-type(odd) > td {
  //     background-color: var(--background-color-row-odd);
  //   }

  //   :global(.htCore) tbody tr:nth-of-type(even) > td {
  //     background-color: var(--background-color-row-even);
  //   }

  //   :global(.htCore) tbody tr:first-child > td {
  //     background-color: var(--background-color-row-first);
  //   }

  /* All headers */
  th {
    background-color: var(--table-header-background);
    color: var(--table-header-color);
  }

  /* Row headers */
  //   :global(.ht_clone_left) th {
  //     background-color: var(--table-header-background);
  //     color: var(--table-header-color);
  //   }

  /* Column headers */
  //   :global(.ht_clone_top) th {
  //     background-color: var(--table-header-background);
  //     color: var(--table-header-color);
  //   }

  /* Row headers */
  //   :global(.ht_clone_top_left_corner) th {
  //     border-bottom: 1px solid var(--table-border-color);
  //   }

  //   :global(.ht_clone_left) th {
  //     border-right: 1px solid var(--table-border-color);
  //     border-left: 1px solid var(--table-border-color);
  //   }

  /* Column headers */
  //   :global(.ht_clone_top) th {
  //     border-top: 1px solid var(--table-border-color);
  //     border-right: 1px solid var(--table-border-color);
  //     border-bottom: 1px solid var(--table-border-color);
  //   }

  //   :global(.ht_clone_top_left_corner) th {
  //     border-right: 1px solid var(--table-border-color);
  //   }

  //   :global(.changeType) {
  //     background: inherit;
  //     border-color: var(--table-border-color);
  //   }

  /* Borders */

  tbody tr th,
  thead tr th {
    border-left: 1px solid var(--table-border-color) !important;
  }

  th,
  td {
    border-right: 1px solid var(--table-border-color);
    border-bottom: 1px solid var(--table-border-color);
  }
  tr:first-child td,
  tr:first-child th {
    border-top: 1px solid var(--table-border-color);
  }

  :global(.ht_master) tr > td {
    border-bottom: 1px solid var(--table-border-color);
  }

  /* Right */
  :global(.ht_master) tr > td {
    border-right: 1px solid var(--table-border-color);
  }

  :global(.htNoFrame) + td,
  :global(.htNoFrame) + th,
  :global(.htRowHeaders) thead tr th:nth-child(2),
  td:first-of-type,
  th:first-child,
  th:nth-child(2) {
    border-left: 1px solid var(--table-border-color);
  }

  :global(.ht_clone_top_left_corner) thead tr th:nth-last-child(2) {
    border-right: 1px solid var(--table-border-color);
  }

  th:last-child {
    border-right: 1px solid var(--table-border-color);
    border-bottom: 1px solid var(--table-border-color);
  }

  /* Selected cell */
  tr > td {
    background-color: var(--cell-color);
  }

  /* Selected cell */
  tr > td.current {
    background-color: var(--cell-selected-color);
  }

  /* Context menu */
  :global(.htContextMenu) tr,
  :global(.htDropdownMenu) tr,
  :global(.htFiltersConditionsMenu) tr {
    background-color: var(--ctx-background);
  }
  :global(.htContextMenu) table tbody tr td,
  :global(.htDropdownMenu) table tbody tr td,
  :global(.htFiltersConditionsMenu) table tbody tr td {
    background-color: var(--ctx-background);
  }
  :global(.htContextMenu) table tbody tr :global(td.current),
  :global(.htContextMenu) table tbody tr :global(td.zeroclipboard-is-hover),
  :global(.htDropdownMenu) table tbody tr :global(td.current),
  :global(.htDropdownMenu) table tbody tr :global(td.zeroclipboard-is-hover),
  :global(.htFiltersConditionsMenu) table tbody tr :global(td.current),
  :global(.htFiltersConditionsMenu)
    table
    tbody
    tr
    :global(td.zeroclipboard-is-hover) {
    background-color: var(--ctx-background-hover);
  }

  :global(.htContextMenu) .handsontable table :global(td.htCustomMenuRenderer),
  :global(.htDropdownMenu)
    .handsontable
    table
    :global(td.htCustomMenuRenderer) {
    background-color: var(--ctx-background);
  }

  :global(.htUISelectCaption) :global(.htUISelectCaption:hover) {
    background-color: var(--ctx-background);
  }

  /* Scroll bar */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: var(--scroll-track);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scroll-thumb);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--scroll-thumb-hover);
  }
}
