:root .KakauPage {
  --color: #202124;
  --border: #dadce0;
  --shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  --background: #fff;
  --background-textarea: #f5f5f5;
  --background: #fff;
  --border: #dadce0;
}

:root:global(.dark) .KakauPage {
  --color: #e8eaed;
  --border: #3c4043;
  --shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  --background: #181818;
  --background-textarea: #1f1f1f;
  --background: #1f1f1f;
  //   --background: #181818;
  --border: #2c2c2c;
}

.Kakau {
}

.KakauPage {
  color: var(--color);
  display: flex;
  flex-direction: column;
  justify-content: start;
  background: var(--background);
  height: 100vh;
  border: 1px solid var(--border);
  width: 100%;
  .KakauBody {
    height: calc(100% - 72px);
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // gap: 36px;
    flex: 1;
    // color: var(--color);
    // background: var(--background);
  }
}

.Toolbar {
  background: var(--background);
  border-bottom: 1px solid var(--border);
  box-shadow: var(--shadow);
  z-index: 1;
}

.CardList {
  background: var(--background);
}

.CardAnswer {
  font-size: 18px;
  margin-bottom: 8px;
  /* font-weight: 500; */
  font-family: "Noto Sans", sans-serif;
  color: grey;
}
.CardQuestion {
  font-size: 22px;
  margin-bottom: 16px;
  font-weight: 500;
  font-family: "Noto Sans", sans-serif;
}

// .BottomTools {
// }

// .BottomList {
//   background: var(--background);
// }

.textArea {
  background: var(--background-textarea);
  color: var(--color);
}
