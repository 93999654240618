:root .Toolbar {
  --color: #202124;
  --border: #dadce0;
  //   --background-toolbar: #f5f5f5;

  --background: white;
  --highlight: #1a73e8;
  --icon: #616161;
  //   --icon: #e8eaed;
  --icon-hover: black;
  --background-icon-hover: rgba(232, 234, 237, 0.1);
}

:root:global(.dark) .Toolbar {
  --color: #e8eaed;
  --border: #2c2c2c;
  //   --background-toolbar: #3b3b3b;

  --background: #181818;
  --highlight: #0078d4;
  --icon: #8c8c8c;
  --icon-hover: #d3d1d1;
}

.Toolbar {
  background: var(--background);
  border-bottom: 1px solid var(--border);
  box-shadow: var(--shadow);
  z-index: 1;

  .redo {
    color: var(--color);
  }
  .swap {
    color: var(--color);
  }
}

.ToolbarItem {
  // font-family: ;
  border-color: var(--highlight);
  color: var(--icon);
  border-bottom: 3px solid transparent;
  &:hover {
    color: var(--icon-hover);
  }
  &.selected {
    color: var(--icon-hover);
    background: rgba(255, 255, 255, 0.05);
    border-bottom: 3px solid #1a73e8;
    // background: blue;
    // color: white;
  }
}

.subscript {
  font-weight: 500;
  color: var(--icon);
  // background: var(--highlight);
}

.ToolbarItem {
  &.selected {
    &.hewa {
      .IconContainer {
        color: white;
        background: #ff5c4ec2;
      }
    }
    &.pololei {
      .IconContainer {
        color: white;
        background: #2bc62bba;
      }
    }
    &.koe {
      .IconContainer {
        color: white;
        background: #5fadc8c2;
      }
    }
  }
}
