:root .Nav {
  --color: #e8eaed;
  --border: #78160d;
  --border: #157ba3;
  --background: #a32015;
  --background: #157ba3;
  --highlight: #0078d4;
  --icon: #616161;
  --icon: #e8eaed;
  --icon-hover: white;
  --background-icon-hover: rgba(232, 234, 237, 0.1);
}

:root:global(.dark) .Nav {
  --color: #e8eaed;
  --border: #2c2c2c;
  --background: #181818;
  --highlight: #0078d4;
  --icon: #8c8c8c;
  --icon-hover: #d3d1d1;
}

// --color-nav-icon: #616161;
// --color-nav-icon-dark: #8c8c8c;
// --color-nav-icon-hover: #333333;
// --color-nav-icon-hover-dark: #d3d1d1;

.Nav {
  color: var(--color);
  border: 1px solid var(--border);
  background: var(--background);

  .NavItem {
    border-color: var(--highlight);
    color: var(--icon);
    &:hover {
      color: var(--icon-hover);
    }
    &.selected {
      color: white;
      // background: rgba(255, 255, 255, 0.05);
    }
  }
}

.IconContainer {
  &.selected {
    background: rgba(255, 255, 255, 0.2);
  }
}

.ThemeButton {
  button {
    position: relative;
    &:active {
      color: white;
      // color: var(--icon-hover);
    }
    &:hover {
      color: white;
      // color: var(--icon-hover);
      background: rgba(255, 255, 255, 0.2);
      // background: var(--background-icon-hover);
    }
  }
}
