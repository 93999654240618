.Player {
}

.question {
  /* color: white; */
  /* font-size: 96px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700; */
}

.questionLink {
  font-size: 96px;
  font-family: "Quicksand", sans-serif;
  cursor: pointer;
  background-color: transparent;
  text-decoration: underline;
  color: #81d2ff;
}

.questionContainer {
}

.optionButtonsContainer {
  display: grid;
  grid-template-columns: 400px 400px;
  grid-row: auto auto;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  margin-bottom: 64px;
}

.option:hover {

}


.nextButtonContainer {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.actionButton {
  border: 2px solid rgba(255, 255, 255, 0.517);
  border-radius: 12px;
  padding: 8px 21px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* color: white; */
  background: transparent;
  font-size: 32px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  text-align: left;
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.actionButton:hover {
  transform: scale(1.05);
  background-color: rgba(255, 255, 255, 0.117);
}

.startScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3f4a66;
  flex-direction: column;
  gap: 100px;
}
