:root {
  --background: white;
  // --background: rgb(255, 166, 166);
}

:root:global(.dark) {
  --background: #181818;
  // --background: rgb(46, 0, 0);
}

html {
  background: var(--background);
}

$widthNav: 96px;
$widthMain: calc(100vw - $widthNav);

.widthNav {
  width: $widthNav;
}
.widthMain {
  width: $widthMain;
}
