:root .KakauToolbar {
  --color: #202124;
  --border: #dadce0;
  //   --background-toolbar: #f5f5f5;

  --background: white;
  --highlight: #1a73e8;
  --icon: #616161;
  //   --icon: #e8eaed;
  --icon-hover: black;
  --background-icon-hover: rgba(232, 234, 237, 0.1);
}

:root:global(.dark) .KakauToolbar {
  --color: #e8eaed;
  --border: #2c2c2c;
  //   --background-toolbar: #3b3b3b;

  --background: #181818;
  --highlight: #0078d4;
  --icon: #8c8c8c;
  --icon-hover: #d3d1d1;
}

.KakauToolbar {
  background: var(--background);
  border-bottom: 1px solid var(--border);
  // box-shadow: var(--shadow);
  z-index: 1;
  width: calc(100vw - 90px);
}

.OpenDropdown {
  background: var(--background);
  box-shadow: var(--shadow);
  border: 1px solid var(--border);
  .header {
    box-shadow: var(--shadow);
    border-bottom: 1px solid var(--border);
  }
  .item {
    border-bottom: 1px solid var(--border);
  }
}
