:root .Resizable {
  --border: #e4e4e4;
  --border-hover: #1a73e8;
}

:root:global(.dark) .Resizable {
  --border: #2c2c2c;
  --border-hover: #1a73e8;
}

.horizontal {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.left {
  position: relative; // so resizer can be positioned
  overflow: hidden;
  max-width: calc(100% - 8px);
}

.right {
  position: relative; // so border can be positioned
  overflow: hidden;
  flex: 1;
}

// sits in the dom between top and bottom
// so that the resizer can overflow, while keeping overflow set to hidden on .top and .bottom
.resizerHorizontal {
  $border: yellow;
  $borderHover: orange;
  // html:global(.dark) > & {
  //   $border: brown;
  //   $borderHover: orange;
  // }

  height: 100%;
  min-height: 20px;
  width: 0px;
  // https://stackoverflow.com/questions/8566090/how-can-i-delay-a-hover-effect-in-css
  border-left: 1px solid var(--border);
  position: relative; // so handle can be positioned
  overflow: visible;
  // cursor: row-resize;
  & .handle {
    transition: 0.15s background-color;
    transition-delay: 0s; // don't delay the disappearance
    &:hover {
      transition-delay: 0.3s; // delay the appearance
      background: var(--border-hover);
    }
    cursor: col-resize;
    z-index: 9999;
    top: 0;
    right: -2px;
    width: 5px;
    height: 100%;
    position: absolute;
  }
}

/////////////
///
///
///
///
///

.vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.top {
  position: relative; // so resizer can be positioned
  overflow: hidden;
  max-height: calc(100% - 8px);
}

.bottom {
  position: relative; // so border can be positioned
  overflow: hidden;
  flex: 1;
}

// sits in the dom between top and bottom
// so that the resizer can overflow, while keeping overflow set to hidden on .top and .bottom
.resizerVertical {
  height: 0px;
  width: 100%;
  // https://stackoverflow.com/questions/8566090/how-can-i-delay-a-hover-effect-in-css
  border-top: 1px solid var(--border);
  position: relative; // so handle can be positioned
  overflow: visible;
  // cursor: row-resize;
  & .handle {
    transition: 0.15s background-color;
    transition-delay: 0s; // don't delay the disappearance
    &:hover {
      transition-delay: 0.3s; // delay the appearance
      background: var(--border-hover);
    }
    cursor: row-resize;
    z-index: 9999;
    left: 0;
    bottom: -2px;
    height: 5px;
    width: 100%;
    position: absolute;
  }
}
