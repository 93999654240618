:root .PlayerPage {
  --color: #202124;
  --border: #dadce0;
  --background: #fff;
  --background-toolbar: #f5f5f5;
  --option-border: grey;
}

:root:global(.dark) .PlayerPage {
  --color: #e8eaed;
  --border: #2c2c2c;
  --background: #181818;
  --background-toolbar: #3b3b3b;
  --option-border: rgba(255, 255, 255, 0.517);
}

.Toolbar {
  background: var(--background-toolbar);
  border-bottom: 1px solid var(--border);
  box-shadow: var(--shadow);
  z-index: 1;
}

.question {
  color: var(--color);
  font-size: 72px;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  padding: 32px;
}

.fontNormal {
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}
.fontOld {
  font-family: "Iowan Old Style", "Palatino Linotype", "URW Palladio L", P052,
    Georgia, serif;
  font-weight: 700;
}
.fontRound {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}

.option {
  display: flex;
  border: 3px solid var(--option-border);
  border-radius: 16px;
  min-height: 120px;
  padding: 12px 24px 24px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.117);
  }
  overflow: hidden;
}

.optionText {
  /* color: white; */
  border: none;
  background: transparent;
  font-size: 32px;
  // font-family: "Quicksand", sans-serif;
  // font-weight: bold;
  text-align: center;
  margin: auto;
  user-select: none;
  //   word-break: keep-all;
  word-wrap: balance;
  //   width: 100%;
}

// --color-nav-icon: #616161;
// --color-nav-icon-dark: #8c8c8c;
// --color-nav-icon-hover: #333333;
// --color-nav-icon-hover-dark: #d3d1d1;

.PlayerPage {
  display: flex;
  flex-direction: column;
  justify-content: start;
  //   justify-content: center;
  //   height: 100vh;
  //   gap: 36px;
  //   flex: 1;
  //   color: var(--color);
  //   border: 1px solid var(--border);
  background: var(--background);
  height: 100vh;
  border: 1px solid var(--border);
  width: 100%;

  .PlayerBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 36px;
    flex: 1;
    color: var(--color);
    // background: var(--background);
  }

  //   .PlayerPageItem {
  //     border-color: var(--highlight);
  //     color: var(--icon);
  //     &:hover {
  //       color: var(--icon-hover);
  //     }
  //     &.selected {
  //       color: var(--icon-hover);
  //       background: rgba(255, 255, 255, 0.05);
  //     }
  //   }
}

// .ThemeButton {
//   button {
//     position: relative;
//     &:active {
//       color: var(--icon-hover);
//     }
//     &:hover {
//       color: var(--icon-hover);
//       background: var(--background-icon-hover);
//     }
//   }
// }
