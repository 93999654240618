:root .IconButton {
  --color: #202124;
  --icon-hover: black;
  --background-icon-hover: #e8ebee;
}

:root:global(.dark) .IconButton {
  --color: #e8eaed;
  --icon: #8c8c8c;
  --icon-hover: #d3d1d1;
  --background-icon-hover: rgba(232, 234, 237, 0.1);
}

.IconButton {
  color: var(--color);
  button {
    position: relative;
    &:active {
      color: var(--icon-hover);
    }
    &:hover {
      color: var(--icon-hover);
      background: var(--background-icon-hover);
    }
  }
}
