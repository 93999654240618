:root .Hiona {
  --color: #202124;
  //   --border: #dadce0;
  //   --shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  //   --background: #fff;
  //   --background-textarea: #f5f5f5;
  //   --background: #fff;
  --border: #dadce0;
}

:root:global(.dark) .Hiona {
  --color: #e8eaed;
  //   --border: #3c4043;
  //   --shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  //   --background: #181818;
  //   --background-textarea: #1f1f1f;
  //   --background: #1f1f1f;
  //   --background: #181818;
  --border: #2c2c2c;
}

.Hiona {
  color: var(--color);
  .option {
    border-color: var(--border);
  }
  .section {
    border-color: var(--border);
  }
  //   font-family: "Noto Sans", "Noto Sans JP";
}

.selected {
  outline: 2px solid #1a73e8;
}

.fontNormal {
  font-family: Inter, -apple-system, system-ui, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}
.fontOld {
  font-family: "Iowan Old Style", "Palatino Linotype", "URW Palladio L", P052,
    Georgia, serif;
  font-weight: 700;
}
.fontRound {
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
}
